import { storageAvailable } from "../utils/storageAvailable";

const textSizeButtons = document.querySelectorAll("[data-textsize]");
const root = document.querySelector(":root");
const storageKey = "userTextSize";

textSizeButtons.forEach((textSizeButton) =>
  textSizeButton.addEventListener("click", changeTextSize)
);

function changeTextSize(evt) {
  let target = evt.currentTarget;
  let action = target.dataset.textsize;
  let currentTextSize = parseInt(getComputedStyle(root).fontSize);
  let newTextSize = "";

  switch (action) {
    case "plus":
      newTextSize = root.style.fontSize =
        currentTextSize < 24
          ? currentTextSize + 2 + "px"
          : currentTextSize + "px";
      saveToLocalStorage(storageKey, newTextSize);
      break;
    case "minus":
      newTextSize = root.style.fontSize =
        currentTextSize > 12
          ? currentTextSize - 2 + "px"
          : currentTextSize + "px";
      saveToLocalStorage(storageKey, newTextSize);
      break;
    case "reset":
      root.style.fontSize = "";
      removeFromLocalStorage(storageKey);
      break;
  }
}

function saveToLocalStorage(key, value) {
  if (storageAvailable("localStorage")) {
    localStorage.setItem(key, value);
  }
}

function removeFromLocalStorage(key) {
  if (storageAvailable("localStorage")) {
    localStorage.removeItem(key);
  }
}

export function setUserTextSizeFromLocalStorage() {
  if (
    storageAvailable("localStorage") &&
    localStorage.getItem(storageKey) !== null
  ) {
    root.style.fontSize = localStorage.getItem(storageKey);
  }
}
